/* eslint-disable max-len */
export const privacyPolicyContentEn = `
<html>
  <p class="c46">
    <span class="c25">Equipment Management Tool Privacy Policy</span>
  </p>
  <p class="c7 c46"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-0 start" start="1">
    <li class="c0 c11 li-bullet-0">
      <span class="c36 c42 c52">Name and Address of the Controller and Data Protection Officer</span>
    </li>
  </ol>
  <p class="c8 c7"><span class="c6"></span></p>
  <p class="c0 c69"><span class="c4">Name and contact information of the Controller:</span></p>
  <p class="c10 c7"><span class="c4"></span></p>
  <p class="c0">
    <span class="c4"
      >Alfred K&auml;rcher SE &amp; Co. KG<br />Alfred-K&auml;rcher-Strasse 28-40<br />71364 Winnenden</span
    >
  </p>
  <p class="c0">
    <span class="c49">Tel. +49 7195 / 14-0<br />E-Mail: </span
    ><span class="c52 c49"><a class="c39" href="mailto:info@karcher.com">info@karcher.com</a></span>
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <p class="c63">
    <span class="c4"
      >(hereinafter referred to as 'Kärcher') carries out all data processing operations (e.g. collection, 
      processing and transfer) in accordance with the statutory regulations.</span
    >
  </p>
  <p class="c0 c7"><span class="c4"></span></p>
  <p class="c69 c71">
    <span class="c4"
      >The following Privacy Policy gives you an overview of the types of data collected on and via our Equipment Manager 
      at https://em.kaercher.com/login (hereinafter referred to as the ‘Portal’), how they are processed, which security measures 
      Kärcher takes to protect your data and how to exercise your rights.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <p class="c62">
    <span class="c48"
      >For questions regarding data protection, and regarding your rights as a Data Subject, please contact the 
      Kärcher data protection officer at:</span
    >
  </p>
  <p class="c7 c62"><span class="c4"></span></p>
  <p class="c0">
    <span class="c4"
      >Alfred K&auml;rcher SE &amp; Co. KG<br />Data Protection Officer<br />Alfred-K&auml;rcher-Strasse 28-40<br />71364
      Winnenden, Germany</span
    >
  </p>
  <p class="c0">
    <span class="c49">E-Mail: </span
    ><span class="c52 c49"
      ><a class="c39" href="mailto:datenschutzbeauftragter@de.kaercher.com"
        >datenschutzbeauftragter@de.kaercher.com</a
      ></span
    ><span class="c4">&nbsp;</span>
  </p>
  <ol class="c1 lst-kix_list_2-0" start="2">
    <li class="c11 c58 li-bullet-0"><span class="c25">General information on data processing</span></li>
  </ol>
  <p class="c12 c7"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-1 start" start="1">
    <li class="c0 c11 li-bullet-0"><span class="c6">Scope of the processing of personal data</span></li>
  </ol>
  <p class="c66 c28">
    <span class="c4"
      >Unless otherwise stated, we process your personal data as a user of our Portal (hereinafter referred to as 
      the ‘Data Subject’) in order to make available and provide you with a functional and user-friendly Portal as 
      well as our content and services.</span
    >
  </p>
  <p class="c51 c7 c28 c73"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="2">
    <li class="c23 c11 li-bullet-0"><span class="c6">Legal basis for the processing of personal data</span></li>
  </ol>
  <p class="c27">
    <span class="c4"
      >To the extent that we obtain the consent of the Data Subject for personal data processing operations, Article 6 (1) a) 
      General Data Protection Regulation (GDPR) serves as the legal basis.</span
    >
  </p>
  <p class="c9 c7 c53"><span class="c4"></span></p>
  <p class="c28 c43">
    <span class="c4"
      >Article 6 (1) b) GDPR serves as the legal basis for processing the personal data required to use the Portal. 
      This is also the case for the processing operations required for carrying out pre-contractual measures.</span
    >
  </p>
  <p class="c28 c30">
    <span class="c4"
      >To the extent that the processing of personal data is required for compliance with a legal obligation to which 
      our company is subject, Article 6 (1) c) GDPR serves as the legal basis.</span
    >
  </p>
  <p class="c9 c7 c53"><span class="c4"></span></p>
  <p class="c28 c59">
    <span class="c4"
      >If the processing is necessary to protect a legitimate interest of our company or a third party, and if the 
      interests, fundamental rights and freedoms of the Data Subject do not outweigh the former interest, Article 6 (1) f) 
      GDPR serves as the legal basis for the processing.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="3">
    <li class="c0 c11 li-bullet-0"><span class="c6">Data erasure and storage period</span></li>
  </ol>
  <p class="c15">
    <span class="c4"
      >The personal data of the Data Subject shall be erased or blocked as soon as the purpose of storage no 
      longer applies.</span
    >
  </p>
  <p class="c9 c7 c53"><span class="c4"></span></p>
  <p class="c28 c32">
    <span class="c4"
      >Data may be stored beyond this period if this has been provided for by the European or national legislator in 
      EU regulations, laws or other regulations to which the Controller is subject. The data shall also be blocked or 
      erased once a storage period prescribed by the standards mentioned expires, unless there is a need for further 
      storage of the data to enter into or perform a contract.</span
    >
  </p>
  <p class="c16 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-0" start="3">
    <li class="c11 c14 li-bullet-0"><span class="c25">Registration on the Portal</span></li>
  </ol>
  <p class="c7 c12"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-1 start" start="4">
    <li class="c0 c11 li-bullet-0"><span class="c6">Description and scope of data processing</span></li>
  </ol>
  <p class="c28 c47">
    <span class="c4"
      >Registration is necessary in order to be able to use the Equipment Manager. You can choose a password linked 
      to your email address. Together, the two enable you to log in more easily when subsequently using the Platform, 
      without having to re-enter your data.</span
    >
  </p>
  <p class="c7 c17"><span class="c4"></span></p>
  <p class="c0 c28"><span class="c4">Kärcher saves the data you have entered in order to set up a user account 
  for your company, which is used to record your visits on behalf of your company. Kärcher will store the data you 
  have entered for further visits as long as you or your company maintain the registration.</span></p>
  <p class="c29 c7 c53"><span class="c4"></span></p>
  <p class="c0 c28">
    <span class="c4">In addition, further voluntary information (e.g. company name and contact details) may be provided. 
    The data are entered into a web form, transmitted to us and stored.</span>
  </p>
  <p class="c12 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="5">
    <li class="c0 c11 li-bullet-0"><span class="c6">Single sign-on service (SSO)</span></li>
  </ol>
  <p class="c28 c74">
    <span class="c4"
      >The data collected during registration is also stored in a single sign-on service connected to the website. 
      This allows you to authenticate yourself in order to use other services offered by Kärcher. During that process, 
      personal data are transmitted to these services once you have agreed to the connection. Before you connect, the 
      data that are passed on to the respective service are displayed and only transmitted to the service with your 
      confirmation. After transmission, data processing is the responsibility of the respective service and is 
      independent of this platform. The respective controller will inform you of the handling of your data on their site. 
      You can therefore obtain information on this from the respective services and their privacy policies.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="6">
    <li class="c0 c11 li-bullet-0"><span class="c6">Legal basis for data processing</span></li>
  </ol>
  <p class="c27">
    <span class="c4"
      >The legal basis for the processing of employees’ personal data is their consent (Sec. 26 (1) German Data 
      Protection Act [Bundesdatenschutzgesetz, BDSG]).</span
    >
  </p>
  <p class="c10 c7 c53"><span class="c4"></span></p>
  <p class="c32 c28">
    <span class="c4"
      >Since the processing of personal data is otherwise necessary for the use of the Portal or for the implementation 
      of pre-contractual measures, the legal basis for any other processing of the data is Article 6 (1) b) GDPR.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="7">
    <li class="c0 c11 li-bullet-0"><span class="c6">Purpose of the data processing</span></li>
  </ol>
  <p class="c27">
    <span class="c4"
      >Using the Portal or creating a user account simplifies the use of the functions provided (e.g. orders). 
      The data may also be processed when the functions selected by the user are activated.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="7">
    <li class="c0 c11 li-bullet-0"><span class="c6">Duration of storage</span></li>
  </ol>
  <p class="c27">
    <span class="c4"
      >When you create a user account, we generally store your data for as long as you maintain the account.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <p class="c32 c28">
    <span class="c4"
      >Connected services independently manage the data transmitted by the SSO service. Erasure of your data on 
      the website is synchronised with the SSO service but not with the connected services. Please note that erasing 
      your account on the website may result in the loss of access to the connected services and the data 
      stored by them.</span
    >
  </p>
  <p class="c10 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="7">
    <li class="c0 c11 li-bullet-0"><span class="c6">Opportunity to erase data</span></li>
  </ol>
  <p class="c27">
    <span class="c4"
      >As a user of the Portal, you have the option to change the data stored there or to erase it yourself at any time. 
      If the data are required for the performance of a contract or to carry out pre-contractual measures, the data can 
      only be erased prematurely if there are no contractual or legal obligations to the contrary. </span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-0" start="4">
    <li class="c8 c11 li-bullet-0"><span class="c25">Provision of the Portal</span></li>
  </ol>
  <p class="c0 c7"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-1 start" start="8">
    <li class="c0 c18 li-bullet-3"><span class="c6">Description and scope of data processing</span></li>
  </ol>
  <p class="c35">
    <span class="c4"
      >Each time our Portal is accessed, our system automatically collects data and information from the accessing computer system.</span
    >
  </p>
  <p class="c7 c17"><span class="c4"></span></p>
  <p class="c0 c28"><span class="c4">The following data are collected in this regard:</span></p>
  <p class="c29 c7 c53"><span class="c4"></span></p>
  <ul class="c1 lst-kix_list_2-2 start">
    <li class="c0 c44 li-bullet-1"><span class="c4">date and time of the request;</span></li>
    <li class="c26 li-bullet-2"><span class="c4">name of the requested file;</span></li>
    <li class="c26 li-bullet-1"><span class="c4">page from which the file was requested;</span></li>
    <li class="c26 li-bullet-2"><span class="c4">access status (transfer file, file not found, etc.);</span></li>
    <li class="c26 li-bullet-1"><span class="c4">web browser and operating system used;</span></li>
    <li class="c26 li-bullet-1"><span class="c4">existing SAP customer number;</span></li>
    <li class="c26 li-bullet-2"><span class="c4">complete IP address of the requesting computer;</span></li>
    <li class="c26 li-bullet-2"><span class="c4">volume of data transmitted.</span></li>
    <li class="c26 li-bullet-2"><span class="c4">robotics data, robot route information and maps</span></li>
  </ul>
  <p class="c0 c7 c53"><span class="c4"></span></p>


  <p class="c9 c7 c34"><span class="c4"></span></p>
  <p class="c38 c50">
    <span class="c4"
      >The data is also stored in our system’s log files.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="9">
    <li class="c8 c18 li-bullet-4"><span class="c6">Legal basis for data processing</span></li>
  </ol>
  <p class="c38 c73 c77">
    <span class="c4"
      >The legal basis for the temporary storage of data and log files is Article 6 (1) f) GDPR.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="10">
    <li class="c18 c23 li-bullet-3"><span class="c6">Purpose of the data processing</span></li>
  </ol>
  <p class="c35">
    <span class="c4"
      >The temporary storage of the IP address by the system is necessary in order to deliver the Portal 
      to the user's computer. For this purpose, the user’s IP address must remain stored for 
      the duration of the session.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <p class="c35">
    <span class="c4"
      >Storage in log files takes place to ensure the functionality of the Portal. In addition, we use the 
      data to optimise the Portal and to ensure the security of our information technology systems.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  
  <p class="c8 c7"><span class="c4"></span></p>
  <p class="c35">
    <span class="c4"
      >These purposes also form the basis for our legitimate interest in data processing in accordance 
      with Article 6 (1) f) GDPR.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="11">
    <li class="c0 c18 li-bullet-3"><span class="c6">Duration of storage</span></li>
  </ol>
  <p class="c13">
    <span class="c4"
      >The data will be erased as soon as they are no longer required to achieve the purpose for 
      which they were collected.</span
    >
  </p>
  <p class="c7 c8"><span class="c4"></span></p>
  
  <ol class="c1 lst-kix_list_2-0" start="5">
    <li class="c0 c2 li-bullet-5"><span class="c6">Use of functional cookies</span></li>
  </ol>
  <p class="c0 c7 c40"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-1 start" start="13">
    <li class="c0 c38 c55 li-bullet-6"><span class="c6">Description and scope of data processing</span></li>
  </ol>
  <p class="c0 c7 c40"><span class="c6"></span></p>
  <p class="c32 c28">
    <span class="c4"
      >Our Portal uses cookies. Cookies are text files that are stored in the Internet browser, or by the 
      Internet browser on the user's computer system. When a user accesses a Portal, a cookie can be stored 
      on the user's operating system. This cookie contains a characteristic character string that enables 
      the browser to be uniquely identified when the Portal is called up again.</span
    >
  </p>
  <p class="c0 c7 c40"><span class="c6"></span></p>
  <p class="c32 c28">
    <span class="c4"
      >We only use functional or technically necessary cookies which enable us to make our Platform as 
      user-friendly as possible.</span
    >
  </p>
  <p class="c32 c7 c38"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="14">
    <li class="c0 c18 li-bullet-7"><span class="c6">Legal basis for data processing</span></li>
  </ol>
  <p class="c32 c7 c38"><span class="c4"></span></p>
  <p class="c13">
    <span class="c4"
      >The legal basis for the processing of personal data using technically necessary cookies is Article 6 (1) f) GDPR.</span
    >
  </p>
  <p class="c7 c20"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="15">
    <li class="c0 c18 li-bullet-3"><span class="c6">Purpose of the data processing</span></li>
  </ol>
  <p class="c7 c20"><span class="c4"></span></p>
  <p class="c32 c38">
    <span class="c4"
      >The purpose of using technically necessary cookies is to simplify the use of the Portal for users. 
      Some functions of our Portal cannot be offered without the use of cookies. The user data collected by 
      technically necessary cookies are not used to create user profiles. These purposes also form the basis 
      for our legitimate interest in the processing of personal data pursuant to Article 6 (1) f) GDPR.</span
    >
  </p>
  <p class="c7 c60"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="16">
    <li class="c0 c18 li-bullet-7"><span class="c6">Opportunity to object</span></li>
  </ol>
  <p class="c7 c60"><span class="c4"></span></p>
  <p class="c32 c38">
    <span class="c4"
      >Cookies are stored on the user's computer and transmitted to our site from there. As a user, you therefore 
      also have full control over the use of cookies. You can deactivate or restrict the transmission of cookies 
      by changing the settings in your Internet browser. Cookies that have already been saved can be erased at 
      any time. This can also be done automatically. If cookies for our Portal are deactivated, it may no longer 
      be possible to use all of the Portal’s functions to their full extent.</span
    >
  </p>
  <p class="c0 c7 c40"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-0" start="6">
    <li class="c0 c2 li-bullet-8">
      <span class="c25">Use of Adobe Analytics</span>
    </li>
  </ol>
  <p class="c10 c7"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-1 start" start="18">
    <li class="c0 c18 li-bullet-7"><span class="c6">Description, purpose and scope of data processing</span></li>
  </ol>
  <p class="c10 c7"><span class="c6"></span></p>
  <p class="c32 c38">
    <span class="c4"
      >Our website uses Adobe Analytics – a web analytics service provided by Adobe Systems Software Ireland Limited, 
      4–6 Riverwalk, City West Business Campus, Dublin 24, Ireland (‘Adobe’).</span
    >
  </p>
  <p class="c10 c7"><span class="c6"></span></p>
  <p class="c32 c38">
    <span class="c4"
      >Adobe Analytics uses cookies that are stored on your computer and enable your use of the website to be analysed. 
      If the information generated by the cookie about the use of the website is transmitted to an Adobe server, then 
      the settings ensure that the IP address is anonymised before geolocation and is replaced by a generic IP address 
      prior to storage. On behalf of the operator of this website, Adobe will use this information to evaluate the 
      use of the website by users in order to compile reports on website activity and to provide the website operator 
      with other services relating to website activity and Internet usage. The IP address transmitted by your browser 
      as part of the Adobe Analytics process will not be merged with other Adobe data. You can prevent cookies from 
      being stored by adjusting the appropriate settings in your browser software. Please note, however, that doing 
      so may prevent you from being able to fully use all the features of this website.</span
    >
  </p>
  <p class="c32 c7 c68"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="19">
    <li class="c0 c18 li-bullet-4"><span class="c6">Legal basis for data processing</span></li>
  </ol>
  <p class="c32 c7 c68"><span class="c4"></span></p>
  <p class="c13">
    <span class="c4"
      >Adobe Analytics cookies are stored on the basis of the visitor’s consent (Art. 6 (1) a). GDPR).</span
    >
  </p>
  <p class="c20 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="20">
    <li class="c0 c18 li-bullet-4"><span class="c6">Duration of storage, possibility of objection and removal</span></li>
  </ol>
  <p class="c20 c7"><span class="c4"></span></p>
  <p class="c13">
    <span class="c4"
      >You can set your browser to notify you when a cookie is placed, to only permit cookies in individual cases, to 
      exclude the use of cookies generally or in specific cases, and even to activate automatic erasure of cookies when 
      you close your browser. Deactivating cookies may restrict the functionality of this website.</span
    >
  </p>
  
  <p class="c20 c7"><span class="c4"></span></p>
  <p class="c13">
    <span class="c4"
      >You can further prevent Adobe from collecting the information generated by the cookie and the data relating to 
      your usage of the website (including your IP address), as well as the processing of such data by Adobe, by revoking 
      your consent under</span
    >
      <span class="c52 c49 c57"
      ><a
        class="c39"
        href="https://www.google.com/url?q=http://www.adobe.com/de/privacy/opt-out.html&amp;sa=D&amp;source=editors&amp;ust=1683717285453866&amp;usg=AOvVaw0T3kEm0iQ_Q_W7Q7K7cWDl"
        >http://www.adobe.com/de/privacy/opt-out.html</a
      ></span
    >
  </p>
  <ol class="c1 lst-kix_list_2-0" start="7">
    <li class="c11 c65 li-bullet-0"><span class="c25">Disclosure to third parties</span></li>
  </ol>
  <p class="c7 c10"><span class="c6"></span></p>
  <p class="c31">
    <span class="c4"
      >We exchange personal data within the Kärcher Group if and to the extent that such is necessary for the performance 
      of a contract or for the implementation of pre-contractual measures pursuant to Article 6 (1) b) GDPR or a legitimate 
      interest arises in an intra-Group transfer in accordance with Article 6 (1) f) GDPR. In this respect, the sales company 
      from the Kärcher Group responsible for the user or the user's employer can access the data processed in and via the 
      Equipment Manager.</span
    >
  </p>
  <p class="c10 c7"><span class="c4"></span></p>
  <p class="c22">
    <span class="c4"
      >In addition to the cases explicitly mentioned in this Privacy Policy, your personal data will only be passed on to 
      third parties without your express prior consent if such is legally permissible or required.</span
    >
  </p>
  <p class="c9 c7"><span class="c4"></span></p>
  <p class="c16">
    <span class="c4"
      >If necessary in order to investigate the illegal or abusive use of the Portal or for legal prosecution, personal 
      data shall be forwarded to the law enforcement authorities or other public authorities and, if necessary, to the 
      third parties which have suffered damage. However, this will only happen if there are indications of illegal or 
      abusive conduct. We are also legally obligated to provide information to certain public bodies upon request. 
      These may be criminal prosecution authorities, public authorities that prosecute administrative offences 
      subject to fines, and the financial authorities.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <p class="c16">
    <span class="c4"
      >Any transfer of personal data is justified by the fact that (1) the processing is necessary for compliance 
      with a legal obligation to transfer data to law enforcement authorities to which we are subject in accordance 
      with Article 6 (1) c) GDPR in conjunction with national legal requirements, or (2) we have a legitimate interest 
      in passing the data on to the named third parties if there are indications of abusive conduct or to enforce 
      legal claims, and your rights and interests in the protection of your personal data within the meaning of 
      Article 6 (1) f) GDPR do not prevail.</span
    >
  </p>
  <p class="c24">
    <span class="c4"
      >Third-party companies and external service providers are used to enable us to offer the services on this Portal. 
      Under Article 28 (1) GDPR, any third-party companies and external service providers used as processors are carefully 
      selected, regularly reviewed and contractually obligated to process all personal data exclusively in accordance 
      with our instructions.</span
    >
  </p>
  <p class="c7 c61"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-0" start="8">
    <li class="c65 c11 li-bullet-0"><span class="c25">Data transfer to third countries</span></li>
  </ol>
  <p class="c8 c7"><span class="c6"></span></p>
  <p class="c63">
    <span class="c4"
      >Unless otherwise stated, data will only be transferred to offices in countries outside of the European 
      Union (third countries) if:</span
    >
  </p>
  <p class="c0 c7"><span class="c4"></span></p>
  <ul class="c1 lst-kix_list_1-0 start">
    <li class="c0 c67 li-bullet-4"><span class="c4">it is necessary for the execution of your orders;</span></li>
    <li class="c10 c67 li-bullet-3"><span class="c4">an adequate level of data protection is ensured; or</span></li>
    <li class="c10 c67 li-bullet-4"><span class="c4">you have granted your consent.</span></li>
  </ul>
  <p class="c20 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-0" start="9">
    <li class="c0 c11 li-bullet-0"><span class="c25">Data security</span></li>
  </ol>
  <p class="c12 c7"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-1 start" start="22">
    <li class="c0 c18 li-bullet-9"><span class="c6">General technical and organisational measures:</span></li>
  </ol>
  <p class="c30 c38">
    <span class="c4"
      >Kärcher has taken a variety of security measures to protect personal information appropriately and adequately. 
      All data stored by Kärcher are protected by physical and technical measures as well as procedural measures that 
      restrict access to the information to specially authorised persons in accordance with this Privacy Policy.</span
    >
  </p>
  <p class="c10 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="23">
    <li class="c8 c18 li-bullet-7"><span class="c6">Secure data transmission</span></li>
  </ol>
  <p class="c32 c38">
    <span class="c4"
      >In the course of an order being processed, your personal data are transferred in encrypted form using the 
      industry-standard Secure Socket Layer ('SSL') technology (SSL encryption version).</span
    >
  </p>
  <p class="c9 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="24">
    <li class="c0 c18 li-bullet-3"><span class="c6">Passwords</span></li>
  </ol>
  <p class="c51 c38 c75">
    <span class="c4"
      >You should never pass on your password for accessing our customer Portal to third parties and you should also 
      change it regularly. When you have finished using your user account, you should log out and close your 
      browser to avoid someone gaining unauthorised access.</span
    >
  </p>
  <p class="c10 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-0" start="10">
    <li class="c0 c11 li-bullet-0"><span class="c25">Rights of the Data Subject</span></li>
  </ol>
  <p class="c12 c7"><span class="c6"></span></p>
  <ol class="c1 lst-kix_list_2-1 start" start="25">
    <li class="c8 c18 li-bullet-7"><span class="c6">Right to information:</span></li>
  </ol>
  <p class="c21">
    <span class="c4"
      >Pursuant to Article 15 GDPR, you can request information on your personal data that we process.</span
    >
  </p>
  <p class="c8 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="26">
    <li class="c0 c18 li-bullet-3"><span class="c6">Right to object:</span></li>
  </ol>
  <p class="c19">
    <span class="c4">You have the right to object for good cause (see Section 8).</span>
  </p>
  <p class="c29 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="27">
    <li class="c0 c18 li-bullet-3"><span class="c6">Right to rectification:</span></li>
  </ol>
  <p class="c32 c38">
    <span class="c4"
      >If information concerning you is not (or is no longer) correct, you can request rectification 
      pursuant to Article 16 GDPR. If your data are incomplete, you can request to have the data 
      completed.</span
    >
  </p>
  <p class="c9 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="28">
    <li class="c0 c18 li-bullet-7"><span class="c6">Right to erasure:</span></li>
  </ol>
  <p class="c51 c38">
    <span class="c4"
      >You can request the erasure of your personal data pursuant to Article 17 GDPR.</span
    >
  </p>
  <p class="c7 c29"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="29">
    <li class="c0 c18 li-bullet-3"><span class="c6">Right to restriction of processing:</span></li>
  </ol>
  <p class="c37">
    <span class="c4"
      >According to Article 18 GDPR, you have the right to request a restriction of processing of your personal data.</span
    >
  </p>
  <p class="c20 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="30">
    <li class="c8 c18 li-bullet-4"><span class="c6">Right to lodge a complaint:</span></li>
  </ol>
  <p class="c19"><span class="c4">If you believe that the processing of your personal</span></p>
  <p class="c38 c72">
    <span class="c4"
      >data violates data protection law, you have the right under Article 77 (1) GDPR to lodge a complaint 
      with a data protection supervisory authority of your choice, or with the data protection supervisory 
      authority that is responsible for the controller.</span
    >
  </p>
  <p class="c7 c9"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="31">
    <li class="c0 c18 li-bullet-3"><span class="c6">Right to data portability:</span></li>
  </ol>
  <p class="c38 c64">
    <span class="c4"
      >In the event that the requirements of Article 20 (1) GDPR are met, you have the right to 
      have data that we process automatically on the basis of your consent or in performance of 
      a contract handed over to you or to third parties.</span
    >
  </p>
  <p class="c9 c7"><span class="c4"></span></p>
  <ol class="c1 lst-kix_list_2-1" start="32">
    <li class="c0 c55 c38 li-bullet-6">
      <span class="c6">Right to object pursuant to Article 21 (1) GDPR:</span>
    </li>
  </ol>
  <p class="c38 c51">
    <span class="c4">You have the right, for reasons arising from your particular situation,</span>
  </p>
  <p class="c38 c54">
    <span class="c4"
      >to object to the processing of your personal data at any time pursuant to Article 6 (1) f) GDPR.</span
    >
  </p>
  <p class="c54 c38" id="h.gjdgxs">
    <span class="c4"
      >The controller will then cease processing the personal data unless the controller demonstrates 
      compelling legitimate grounds for the processing which override the interests, rights and freedoms 
      of the Data Subject, or if the processing takes place for the establishment, exercise or defence 
      of legal claims.</span
    >
  </p>
</html>
`;
