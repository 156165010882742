import { useSelector } from 'react-redux';
import { useAnalyticsSetPageInfo } from '../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetPageInfo';
import { StyledPrivacyPolicy } from './PrivacyPolicy.styles';
import { privacyPolicyContentDe } from './language/de/PrivacyPolicyHtml';
import { privacyPolicyContentPt } from './language/pt/PrivacyPolicyHtml';
import { privacyPolicyContentEn } from './language/en/PrivacyPolicyHtml';
import { selectUserCountryCode } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';
import { Country } from 'app/cross-cutting-concerns/translations/interfaces/Translations.types';

export const PrivacyPolicy = (): JSX.Element => {
  useAnalyticsSetPageInfo({});
  const userCountryCode = useSelector(selectUserCountryCode);
  const country = Translations.getCountryByCountryCode(userCountryCode);

  // TODO: Add privacy policy content for other languages when having the content
  const ContentByCountryMap: Record<Country, any> = {
    [Country.AUSTRALIA]: privacyPolicyContentEn,
    [Country.AUSTRIA]: privacyPolicyContentDe,
    [Country.BELGIUM]: privacyPolicyContentEn,
    [Country.CZECH_REPUBLIC]: privacyPolicyContentEn,
    [Country.DENMARK]: privacyPolicyContentEn,
    [Country.FRANCE]: privacyPolicyContentEn,
    [Country.GERMANY]: privacyPolicyContentDe,
    [Country.ITALY]: privacyPolicyContentEn,
    [Country.NETHERLANDS]: privacyPolicyContentEn,
    [Country.NORWAY]: privacyPolicyContentEn,
    [Country.SWEDEN]: privacyPolicyContentEn,
    [Country.SWITZERLAND]: privacyPolicyContentDe,
    [Country.UNITED_KINGDOM]: privacyPolicyContentEn,
    [Country.USA]: privacyPolicyContentEn,
    [Country.SPAIN]: privacyPolicyContentEn,
    [Country.JAPAN]: privacyPolicyContentEn,
    [Country.PORTUGAL]: privacyPolicyContentPt,
    [Country.KOREA]: privacyPolicyContentEn,
  };

  const content = ContentByCountryMap[country];

  return (
    <StyledPrivacyPolicy className="privacy-policy">
      <div className="privacy-policy__content" dangerouslySetInnerHTML={{ __html: content }}></div>
    </StyledPrivacyPolicy>
  );
};
